.resume {
  width: 100vw;
  height: 100vh;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resume > nav {
  align-self: flex-start;
}

.resume > nav span {
  color: #DEBB00 !important; 
}

.resume > iframe {
  width: 70%;
  height: 100%;
  margin-top: 2rem;
  border: none;
}

@media only screen and (max-width: 768px) {
  .resume {
    padding: 24px;
  }
  .resume > iframe {
    width: 100%;
    height: 100%;
    margin-top: 48px;
  }
  .resume > nav {
    align-self: unset;
  }
}